<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="{
			'pb-5': !['xs', 'sm'].includes(windowWidth),
			'px-3 pb-3': ['xs', 'sm'].includes(windowWidth),
		}"
		class="container">
		<div
			class="row no-gutters"
			:style="rowStyle">
			<div
				:class="{
					'd-flex align-items-start': !['xs', 'sm'].includes(windowWidth),
					'justify-content-start': ['xs', 'sm'].includes(windowWidth) && reversed,
					'order-12': ['xs', 'sm'].includes(windowWidth) || reversed,
					'justify-content-end': !['xs', 'sm'].includes(windowWidth) && !reversed,
				}"
				class="col-12 col-md-6">
				<div
					:class="{ 'justify-content-center py-3': ['xs', 'sm'].includes(windowWidth) }"
					class="row">
					<div :class="['xs', 'sm'].includes(windowWidth) ? 'col' : 'col-auto'">
						<div
							:class="{
								'ml-4': !['xs', 'sm'].includes(windowWidth) && reversed,
								'mr-4': !['xs', 'sm'].includes(windowWidth) && !reversed,
							}">
							<div
								v-if="!!header"
								class="row mb-4">
								<div class="col">
									<header-block>
										<div v-html="header" />
									</header-block>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<collapsible-container
										:top-content="topContent"
										:bottom-content="bottomContent"
										toggle-class="product-primary-color" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 m-0">
				<img
					:src="image"
					:class="{
						'pr-3': !['xs', 'sm'].includes(windowWidth) && reversed,
						'pl-3': !['xs', 'sm'].includes(windowWidth) && !reversed,
					}"
					class="w-100">
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import HeaderBlock from '../HeaderBlock';
import CollapsibleContainer from './CollapsibleContainer';

export default {
	name: 'ImageSection',
	components: {
		CollapsibleContainer,
		HeaderBlock,
	},
	mixins: [WindowSizes],
	props: {
		image: {
			type: String,
			default: '',
		},
		header: {
			type: String,
			default: '',
		},
		topContent: {
			type: String,
			default: '',
		},
		bottomContent: {
			type: String,
			default: '',
		},
		rowStyle: {
			type: String,
			default: '',
		},
		reversed: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
