<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section
		:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'mt-5': 'py-5'"
		class="w-100"
		:background-style="backgroundStyle">
		<div
			:class="['xs', 'sm'].includes(windowWidth) ? 'mb-0' : 'p-4'"
			class="h-75 d-flex align-items-center justify-content-center mw-100 p-0 mt-4 m-0">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'no-gutters'"
				class="row">
				<div class="col-12">
					<div v-if="mainProductLoading">
						<is-loading
							:loading="mainProductLoading"
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')" />
					</div>
					<div v-else>
						<div class="row">
							<!-- Carousel -->
							<div
								class="col-12 col-md-6"
								:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'px-3' : 'pl-4 pr-5'">
								<div class="row">
									<div class="col-12 col-md-11">
										<image-carousel
											:images="galleryImages"
											group-to-show="defaultGroup"
											:enable-zoom="true"
											modal-id="intro"
											:highlight-color="highlightColor"
											no-background />
									</div>
									<div class="col-1 d-none d-md-block" />
								</div>
							</div>
							<!-- Product Information -->
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? '' : 'pl-0'"
								class="col-12 col-md-6 d-flex flex-column justify-content-center ">
								<div
									class="d-inline section-header product-primary-color my-4"
									style="font-size: 2.5rem !important;"
									v-html="title" />
								<p
									class="mb-2"
									v-html="description" />
								<div class="row mb-4 pb-3">
									<div class="col">
										<img
											v-for="(image, index) in variantsImages"
											:key="`variant-${index}`"
											:src="image"
											style="max-width: 60px">
									</div>
								</div>
								<!-- <p class="h2 font-weight-bold pb-2 product-secondary-color">
									{{ mainProductPrice }}
								</p> -->
								<div class="row no-gutters my-2">
									<div class="col-6 col-md-6 pl-0 pr-2">
										<a
											:href="$router.resolve({ name: 'ProductDetails', params: { productName: product } }).href"
											target="_blank">
											<div class="w-100 btn border-2 text-white rounded-0 pointer cta font-weight-bolder">
												<div class="py-2">
													{{ translate('buy_now') }}
												</div>
											</div>
										</a>
									</div>
									<div class="col-6 col-md-6 pl-0 pr-2">
										<div
											v-b-modal="'modal-introduction-video'"
											class="w-100 btn btn-outline border-2 rounded-0 pointer cta font-weight-bolder">
											<div class="py-2">
												{{ translate('watch_video') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			id="modal-introduction-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="videoUrl[language]"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</page-section>
</template>

<script>
import ImageCarousel from '@/components/ProductImageCarousel';
import isLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';
import {
	PRODUCT_DEFAULT_PRICE,
	PRODUCT_OVERRIDE_PRICE,
} from '@/settings/Products';
import { LandingPages, Store } from '@/translations';
import Products from '@/util/Products';
import PageSection from '../PageSection';
import PublicStore from '@/mixins/PublicStore';

export default {
	name: 'Product2Introduction',
	messages: [LandingPages, Store],
	components: {
		isLoading,
		ImageCarousel,
		PageSection,
	},
	mixins: [WindowSizes, PublicStore],
	props: {
		product: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		variantsImages: {
			type: Array,
			required: true,
		},
		galleryImages: {
			type: Object,
			required: true,
		},
		videoUrl: {
			type: Object,
			default() {
				return {};
			},
		},
		highlightColor: {
			type: String,
			required: true,
		},
		backgroundStyle: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			mainProduct: new Products(),
			alert: new this.$Alert(),
			outOfStockProducts: {},
		};
	},
	computed: {
		mainProductData() {
			try {
				const { data } = this.mainProduct.data.response.data;
				return {
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					children: data.attributes.children,
				};
			} catch (error) {
				return [];
			}
		},
		mainProductPrice() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
					return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
				}
				return this.mainProductData.price;
			} catch (e) {
				return this.mainProductData.price;
			}
		},
		mainProductLoading() {
			return !!this.mainProduct.data.loading;
		},
	},
	watch: {
		async country() {
			this.initProductInfo();
		},
	},
	mounted() {
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' }).catch(() => {});
			return;
		}
		this.initProductInfo();
	},
	methods: {
		initProductInfo() {
			this.mainProduct.getProductDetails(this.product).catch(() => {});
		},
		getProductPrice(product, priceKey = 'price') {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
	},
};
</script>
<style lang="scss" scoped>
.cta {
	&.btn:not(.btn-outline) {
		&:not(:hover) {
			background-color: var(--product-primary-color);
		}
		&:hover {
			background-color: var(--product-secondary-color) !important;
			border-color: var(--product-secondary-color) !important;
		}
	}
	&.btn-outline {
		&:not(:hover) {
			border-color: var(--product-primary-color);
			color: var(--product-primary-color);
		}
		&:hover {
			border-color: var(--product-secondary-color) !important;
			color: var(--product-secondary-color) !important;
		}
	}
}
</style>
