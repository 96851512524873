<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="h-100">
		<div v-html="topContent" />
		<div v-if="!collapsed">
			<div v-html="bottomContent" />
		</div>
		<span v-if="collapsed && !!bottomContent"> ... </span>
		<div
			v-if="!!bottomContent"
			class="text-right">
			<span
				:class="toggleClass"
				class="pointer font-weight-bolder"
				@click="collapsed = !collapsed">
				{{ collapsed ? `${translate('read_more')} >` : translate('read_less') }}
			</span>
		</div>
	</div>
</template>
<script>
import { LandingPages } from '@/translations';

export default {
	name: 'CollapsibleContainer',
	messages: [LandingPages],
	props: {
		topContent: {
			type: String,
			default: '',
		},
		bottomContent: {
			type: String,
			default: '',
		},
		toggleClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			collapsed: true,
		};
	},
};
</script>
