<template>
	<div class="overview bg-white">
		<image-section
			v-for="(section, index) in sections"
			:key="`overview-${index}`"
			:header="section.header"
			:image="section.image"
			:top-content="section.content.top"
			:bottom-content="section.content.bottom"
			:row-style="['xs', 'sm'].includes(windowWidth) ? '' : `height: 100% !important;`"
			:reversed="section.reversed"
			:class="{
				'mb-5': !['xs', 'sm'].includes(windowWidth) && index !== sections.length - 1,
			}" />
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import ImageSection from './ImageSection';

export default {
	name: 'Overview',
	components: {
		ImageSection,
	},
	mixins: [WindowSizes],
	props: {
		sections: {
			type: Array,
			required: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.overview::v-deep {
	h2 {
		color: var(--product-primary-color);
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
		letter-spacing: 3px;

		@media only screen and (max-width: 767px) {
			font-size: 32px;
		}
		@media only screen and (min-width: 768px) {
			font-size: 48px;
		}
	}
	h3 {
		font-size: 36px;
		line-height: 44px;
		letter-spacing: 1.6px;
		color: #666;
	}
}
</style>
