var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{
		'pb-5': !['xs', 'sm'].includes(_vm.windowWidth),
		'px-3 pb-3': ['xs', 'sm'].includes(_vm.windowWidth),
	}},[_c('div',{staticClass:"row no-gutters",style:(_vm.rowStyle)},[_c('div',{staticClass:"col-12 col-md-6",class:{
				'd-flex align-items-start': !['xs', 'sm'].includes(_vm.windowWidth),
				'justify-content-start': ['xs', 'sm'].includes(_vm.windowWidth) && _vm.reversed,
				'order-12': ['xs', 'sm'].includes(_vm.windowWidth) || _vm.reversed,
				'justify-content-end': !['xs', 'sm'].includes(_vm.windowWidth) && !_vm.reversed,
			}},[_c('div',{staticClass:"row",class:{ 'justify-content-center py-3': ['xs', 'sm'].includes(_vm.windowWidth) }},[_c('div',{class:['xs', 'sm'].includes(_vm.windowWidth) ? 'col' : 'col-auto'},[_c('div',{class:{
							'ml-4': !['xs', 'sm'].includes(_vm.windowWidth) && _vm.reversed,
							'mr-4': !['xs', 'sm'].includes(_vm.windowWidth) && !_vm.reversed,
						}},[(!!_vm.header)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('header-block',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.header)}})])],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('collapsible-container',{attrs:{"top-content":_vm.topContent,"bottom-content":_vm.bottomContent,"toggle-class":"product-primary-color"}})],1)])])])])]),_c('div',{staticClass:"col-12 col-md-6 m-0"},[_c('img',{staticClass:"w-100",class:{
					'pr-3': !['xs', 'sm'].includes(_vm.windowWidth) && _vm.reversed,
					'pl-3': !['xs', 'sm'].includes(_vm.windowWidth) && !_vm.reversed,
				},attrs:{"src":_vm.image}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }