<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section class="summary-section">
		<div class="row no-gutters my-5">
			<div class="col d-md-flex align-items-end">
				<div class="row">
					<div class="col-12 col-md-6 col-lg-4 d-flex justify-content-end align-items-start">
						<div class="d-block">
							<img
								class="w-100 mw-100 mh-100"
								style="height: auto"
								:src="image">
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-8">
						<div class="row mt-3">
							<div class="col">
								<header-block
									:content="headerContent"
									text-centered />
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div v-html="description" />
							</div>
						</div>
						<div class="row">
							<div class="col">
								<a
									:href="brochureUrl"
									target="_blank"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'px-3'"
									class="btn btn-lg text-white pointer rounded-0 py-1 bg-product-primary">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? '' : ''"
										class="font-weight-bold h6 my-2 mx-1">
										{{ translate('download_brochure') }}
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import HeaderBlock from '../HeaderBlock';
import PageSection from '../PageSection';
import Scroll from '../../mixins/Scroll';

export default {
	name: 'Summary',
	messages: [LandingPages],
	components: {
		HeaderBlock,
		PageSection,
	},
	mixins: [Scroll, WindowSizes],
	props: {
		image: {
			type: String,
			required: true,
		},
		headerContent: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		brochureUrl: {
			type: String,
			default: '',
		},
	},
};
</script>
<style lang="scss" scoped>
.summary-section::v-deep {
	h3 {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
		margin: 0;
		text-align: center;
		color: var(--product-primary-color);
		line-height: 30px;
		letter-spacing: 1px;
	}
}
</style>
