var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-section',{staticClass:"tabs-section"},[_c('b-tabs',{attrs:{"vertical":!['xs', 'sm'].includes(_vm.windowWidth)},on:{"input":function($event){_vm.currentTab = $event}}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tab-title-block",class:{
						'tab-active': Object.keys(_vm.tabs).findIndex(function (keyName) { return keyName === 'benefits'; }) === _vm.currentTab,
					}},[_c('div',{staticClass:"tab-title-container"},[_vm._v(" "+_vm._s(_vm.translate('benefits'))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"py-5 px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.tabs.benefits.image}})]),_c('div',{staticClass:"col-12 col-lg-6 content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tabs.benefits.content)}})])])])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tab-title-block",class:{
						'tab-active': Object.keys(_vm.tabs).findIndex(function (keyName) { return keyName === 'ingredients'; }) === _vm.currentTab,
					}},[_c('div',{staticClass:"tab-title-container"},[_vm._v(" "+_vm._s(_vm.translate('ingredients'))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"py-5 px-3 ingredients content",domProps:{"innerHTML":_vm._s(_vm.tabs.ingredients.content)}})]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tab-title-block",class:{
						'tab-active': Object.keys(_vm.tabs).findIndex(function (keyName) { return keyName === 'supplementsFacts'; }) === _vm.currentTab,
					}},[_c('div',{staticClass:"tab-title-container"},[_vm._v(" "+_vm._s(_vm.translate('supplements_facts'))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"py-5 px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.tabs.supplementsFacts.images.supplementsFacts}})]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.tabs.supplementsFacts.images.labels}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tabs.supplementsFacts.content)}})])])])])])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tab-title-block",class:{
						'tab-active': Object.keys(_vm.tabs).findIndex(function (keyName) { return keyName === 'faqs'; }) === _vm.currentTab,
					}},[_c('div',{staticClass:"tab-title-container"},[_vm._v(" "+_vm._s(_vm.translate('faqs'))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"faq-accordion",class:['xs','sm'].includes(_vm.windowWidth) ? 'p-1' : 'py-5 px-3'},_vm._l((_vm.tabs.faqs),function(faq,index){return _c('b-card',{key:("faq-" + index),ref:("faq-" + index),refInFor:true,staticClass:"bg-white m-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("faq-" + index)),expression:"`faq-${index}`"}],staticClass:"text-left font-weight-bold",attrs:{"block":"","href":"#","variant":"default"}},[_c('div',{staticClass:"d-inline",domProps:{"innerHTML":_vm._s(faq.q)}}),_c('span',{staticClass:"when-opened"},[_c('i',{staticClass:"fas fa-angle-up rotate-icon mr-2"})]),_c('span',{staticClass:"when-closed"},[_c('i',{staticClass:"fas fa-angle-down rotate-icon mr-2"})])])],1),_c('b-collapse',{attrs:{"id":("faq-" + index),"visible":"","accordion":"faq-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',{domProps:{"innerHTML":_vm._s(faq.a)}})],1)],1)],1)}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }